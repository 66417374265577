var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.object && !_vm.object.complete)?_c('div',{staticClass:"question__answers"},_vm._l((_vm.object.answers),function(answer,index){return _c('div',{key:index,staticClass:"question__answers__item"},[_c('AnswerButton',{attrs:{"active":!_vm.process && !_vm.object.complete,"type":_vm.object.passed && answer.chosen ? 'success' : '',"text":(!answer.female_text
            ? answer.text
            : _vm.user.male
            ? answer.text
            : answer.female_text
          )
            .replace(/%name%/g, _vm.user.first_name)
            .replace(/%surname%/g, _vm.user.last_name)
            .replace(/%points%/g, _vm.pageData.user_progress.points)},on:{"click":function($event){return _vm.sendAnswer(answer, index)}}})],1)}),0):_vm._e(),(_vm.object && _vm.object.complete)?_c('div',{staticClass:"message",staticStyle:{"margin-top":"40px"},attrs:{"id":'questionuserchoice-' + _vm.sequence_no}},[_c('div',{staticClass:"message-answer right"},[_c('div',{staticClass:"message__author d-none d-sm-flex"},[_c('div',{staticClass:"message__author__avatar right"},[_c('img',{attrs:{"src":_vm.user.avatar.replace('http://', 'https://'),"alt":""}})])]),_c('div',{staticClass:"message__content w-100 w-sm-80"},[_c('div',{staticClass:"message__content__char"},[_c('img',{staticClass:"d-table d-sm-none char_img_mobile",attrs:{"src":_vm.user.avatar.replace('http://', 'https://'),"alt":""}}),_c('div',{staticClass:"message__content__char__name"},[_vm._v(" "+_vm._s(_vm.user.first_name)+" "+_vm._s(_vm.user.last_name)),_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v(", Это вы")])])]),_c('div',{staticClass:"message__content__text",domProps:{"innerHTML":_vm._s(
            (!_vm.object.answers[_vm.object.user_answers].chosen_female_text
              ? _vm.object.answers[_vm.object.user_answers].chosen_text
              : _vm.user.male
              ? _vm.object.answers[_vm.object.user_answers].chosen_text
              : _vm.object.answers[_vm.object.user_answers].chosen_female_text
            )
              .replace(/%name%/g, _vm.user.first_name)
              .replace(/%surname%/g, _vm.user.last_name)
              .replace(/%points%/g, _vm.pageData.user_progress.points)
          )}}),_c('div',{staticClass:"message__content__triangle d-none d-sm-block right"})])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }