var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"question"},[_c('div',{staticClass:"question__content",class:{ answered: _vm.object.user_answer }},[_c('ContentHeader',{attrs:{"type":_vm.object.title && _vm.object.title != ''
          ? _vm.object.title
          : 'Вопрос с проверкой значения',"text":_vm.object.text,"description":_vm.object.text_description}}),(!_vm.object.user_answer)?_c('div',[(_vm.object.parent_message)?_c('CharMessage',{staticClass:"mb-2",attrs:{"object":_vm.object.parent_message,"direction":"left"}}):_vm._e(),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.answer),expression:"answer"}],attrs:{"rows":"4","placeholder":"Ваш ответ"},domProps:{"value":(_vm.answer)},on:{"input":function($event){if($event.target.composing){ return; }_vm.answer=$event.target.value}}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.process && !_vm.validAnswer)?_c('div',{staticClass:"question__content__error"},[_vm._v(" Введите хотя бы 1 символ ")]):_vm._e()]),_c('div',{staticClass:"question__content__button"},[_c('FormButton',{attrs:{"loading":_vm.loading,"text":"Отправить","inactive":_vm.process,"size":"medium-large","color":"primary"},on:{"click":_vm.sendAnswer}})],1)],1):_c('div',{attrs:{"id":'questionanswercheck-' + _vm.sequence_no}},[(_vm.object && _vm.object.parent_message)?_c('CharMessage',{staticClass:"mb-2",attrs:{"object":_vm.object.parent_message,"direction":"left"}}):_vm._e(),_c('AnswerCharMessage',{attrs:{"object":_vm.object.user_answer,"is_user":true}}),(_vm.user.male)?_c('AuthorAnswer',{attrs:{"text":_vm.object.is_correct ? _vm.object.postreply_text : _vm.object.postreply_error_text}}):_c('AuthorAnswer',{attrs:{"text":_vm.object.is_correct
            ? _vm.object.postreply_female_text
              ? _vm.object.postreply_female_text
              : _vm.object.postreply_text
            : _vm.object.postreply_error_female_text
            ? _vm.object.postreply_error_female_text
            : _vm.object.postreply_error_text}}),_c('AfterTaskMessage')],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }